import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'
import previewFileAudioComponent from '@/components/PreviewFileAudio'

export default {
    name: 'ColorsView',
    data() {
        return {
            colorFigure: {},
            currentColorFigureId: null,
            fileFilledImage: null,
            fileBorderImage: null,
            fileColoredImage: null,
            fileAudio: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout,
        previewFileAudioComponent
    },
    mounted() {
        this.currentColorFigureId = this.currentRoute().params.id

        this.getColor()
    },
    methods: {
        getColor() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/colors-figures/' + self.currentColorFigureId,
                method: 'GET'
            }).then(function (response) {
                self.colorFigure = response.data.color_figure
            })
        },
        deleteColor() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/colors-figures/' + self.currentColorFigureId,
                method: 'DELETE'
            }).then(function () {
                self.$router.push({ name: 'admin.colors.figures' })
            })
        },
        updateColor() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            formData.append('name', self.colorFigure.name)
            if (self.fileFilledImage) formData.append('filled_image', self.fileFilledImage)
            if (self.fileBorderImage) formData.append('border_image', self.fileBorderImage)
            if (self.fileColoredImage) formData.append('colored_image', self.fileColoredImage)
            if (self.fileAudio) formData.append('audio', self.fileAudio)
            formData.append('is_amel', self.colorFigure.is_amel)

            httpAxios({
                url: self.$backendUrl + '/api/v1/colors-figures/' + self.currentColorFigureId,
                method: 'POST',
                data: formData
            }).then(function (response) {
                self.colorFigure = response.data.color_figure

                self.notifySuccess()
            })
        },
        handleFileFilledImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileFilledImage = null
            }

            this.fileFilledImage = files[0]
        },
        handleFileBorderImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileBorderImage = null
            }

            this.fileBorderImage = files[0]
        },
        handleFileColoredImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileColoredImage = null
            }

            this.fileColoredImage = files[0]
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        }
    }
}